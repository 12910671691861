<template>
    <div class="landing-page">
        <!-- header -->
        <div class="header">
            <div class="container d-flex align-items-center">
                <div class="menu">
                    <div class="logo">
                        <img class="img-logo-header" src="../../../assets/icons/football/ic_logoKickdun.png">
                    </div>
                    <div class="menu-list mobile">
                        <Dropdown class="language" v-model="lang" :options="langArr" optionLabel="label">
                            <template #value="slotProps">
                                <div class="country-item country-item-value">
                                    <img :src="slotProps.value.flag" class="flat-icon"/>
                                    <span class="lang-text">{{slotProps.value.label}}</span>
                                </div>
                            </template>
                            <template #option="slotProps">
                                <div class="country-item country-item-value">
                                    <img :src="slotProps.option.flag" class="flat-icon"/>
                                    <span class="lang-text">{{slotProps.option.label}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <i class="pi pi-bars" @click="showMenuListItems = true" v-if="!showMenuListItems"></i>
                        <i class="pi pi-times" @click="showMenuListItems = false" v-else></i>
                    </div>
                    <ul class="menu-list desktop">
                        <li class="menu-items" v-for="(items, index) in menuItems" :key="index">
                            <a @click="scrollMenuItems($event, items.link)">{{items.name}}</a>
                        </li>
                    </ul>
                </div>
                <div class="desktop download-menu">
                    <Dropdown class="language" v-model="lang" :options="langArr" optionLabel="label">
                        <template #value="slotProps">
                            <div class="country-item country-item-value">
                                <img :src="slotProps.value.flag" class="flat-icon"/>
                                <span class="lang-text">{{slotProps.value.label}}</span>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="country-item country-item-value">
                                <img :src="slotProps.option.flag" class="flat-icon"/>
                                <span class="lang-text">{{slotProps.option.label}}</span>
                            </div>
                        </template>
                    </Dropdown>
                    <div class="download-btn">
                        <span class="download-text">지금 다운로드</span>
                        <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/ic_apple.png" class="download-icon" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/ic_android.png" class="download-icon" /></a>
                    </div>
                </div>
            </div>
            <div class="menu-list-items" v-if="showMenuListItems">
                <ul class="menu-list mobile">
                    <li class="menu-items" v-for="(items, index) in menuItems" :key="index">
                        <a @click="scrollMenuItems($event, items.link)">{{items.name}}</a>
                    </li>
                </ul>
                <div class="mobile download-menu">
                    <div class="download-btn">
                        <span class="download-text">지금 다운로드</span>
                        <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/ic_apple.png" class="download-icon" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/ic_android.png" class="download-icon" /></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="head-banners">
            <!-- <div class="mr-0 background-header"></div> -->
            <img src="../../../assets/icons/football/img_bannerhome.png" style="width: 100%; height: 100%;" />
            <div class="container">
                <div class="text-banner col-md-6">
                    <div class="header-text-banner">
                        <div>킥던은 축구와 풋살을 위한<br/> 커뮤니티 서비스입니다</div>
                    </div>
                    <div class="body-text-banner">
                        함께 플레이할 클럽과 멤버를 찾고 계신가요? 저희와 함께 하세요. <br/>빠르고 쉽게 클럽을 만들고, 경쟁자를 찾고, 경기를 즐길 수 있습니다.
                    </div>
                    <!--<div class="footer-text-banner">
                        <Button label="더보기" class="p-button-success main-style-button" />
                    </div>-->
                </div>
                <div class="text-banner right col-md-6">
                    <img src="../../../assets/icons/football/banner_image.png" style="height: 85%;" />
                </div>
            </div>
        </div>
        <!-- body -->
        <div class="body">
            <div id="app-featureds">
                <div class="function-title">앱 기능</div>
                <div class="container">
                    <div class="function">
                        <div class="function-banner desktop">
                            <!-- <img class="function-img" src="../../../assets/icons/football/img_appscreen.png"/> -->
                            <slider ref="slider" :options="options">
                                <!-- slideritem wrapped package with the components you need -->
                                <slideritem v-for="(item,index) in imgArr" :key="index">
                                    <img class="function-img" :src="item.img"/>
                                </slideritem>
                                <!-- Customizable loading -->
                                <div slot="loading">로딩 중...</div>
                            </slider>
                        </div>
                        <div class="function-box">
                            <div class="function-items" :class="`${[(functions.length-1), (functions.length-2)].includes(index) ? 'last-items' : ''}`" v-for="(items, index) in functions" :key="index">
                                <div class="items-header">
                                    <img class="items-header-icon" :src="items.icon"/>
                                </div>
                                <div class="items-body">
                                    <div class="items-title">
                                        {{items.title}}
                                    </div>
                                    <div class="items-content">
                                        {{items.content}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="work">
                <div class="function-title">이 앱의 작동 방식</div>
                <div class="container">
                    <div class="function">
                        <div class="partner-box" v-for="(partner, index) in partners" :key="index">
                            <div class="partner-header">
                                <img class="partner-logo" :src="partner.logo"/>
                            </div>
                            <div class="partner-sub-header">
                                <!-- <img class="partner-logo" :src="partner.logo"/> -->
                                {{ partner.title }}
                                <div class="partner-box-line"></div>
                            </div>
                            <div class="partner-body">
                                {{ partner.content }}
                            </div>
                            <!-- <div class="partner-footer">
                                <div class="partner-box-line"></div>
                                <a href="#">Techtronic Industries</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="container banners">
                <div class="text-banner">
                    편리한 기능을 경험하려면 앱을 다운로드하세요
                </div>
                <!-- <div class="download-btn"> -->
                    <!-- <span class="download-text">지금 다운로드</span>
                    <img src="../../../assets/icons/football/ic_apple.png" class="download-icon" />
                    <img src="../../../assets/icons/football/ic_android.png" class="download-icon" /> -->
                <!-- </div> -->
                <div class="d-flex mt-3 mb-3 justify-content-center">
                    <div class="download-icon mr-3">
                        <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/app-chplay-1.png" /></a>
                    </div>
                    <div class="download-icon">
                        <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/app-ios-1.png" /></a>
                    </div>
                </div>
            </div>

            
        </div>

        <!-- footer -->
        <div class="footer">
            <div class="container row m-auto pt-5">
                <div class="col-lg-6 col-12 pt-2 mb-4 px-0">
                    <div class="logo-footer">
                        <img class="img-logo-footer" src="../../../assets/icons/football/Logo_kickdun_white.png"/>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 px-0 pb-3">
                    <div class="about-us">
                        축구 애호가를 위한 궁극의 모바일 앱, Kickdun! Kickdun은 축구 커뮤니티를 하나로 모으기 위해 설계되었으며, 축구 클럽을 만들고, 팀원을 찾고, 상대에게 도전하고, 최신 팀 뉴스를 최신 상태로 유지할 수 있는 원활한 플랫폼을 제공합니다.
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 px-0 pb-3">
                    <div class="company-name">KICKDUN COMPANY</div>
                    <div class="company-info mt-2 mb-2">
                        <div class="email">이메일: kickdungo@gmail.com</div>
                        <div class="policy"><a @click="goPolicy()">개인 정보 정책</a></div>
                        <div class="d-flex" style="margin-top: 10px;">
                            <div class="social-icon mr-3">
                                <a href="https://www.facebook.com/profile.php?id=61558079368197&sk=about" target="_blank"><img src="../../../assets/icons/football/ic_facebook.png" /></a>
                            </div>
                            <div class="social-icon">
                                <a href="https://www.instagram.com/kickdun" target="_blank"><img src="../../../assets/icons/football/ic_instagram.png" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 px-0 pb-3">
                    <div class="d-flex mb-5">
                        <div class="download-icon mr-3">
                            <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/app-chplay-1.png" /></a>
                        </div>
                        <div class="download-icon">
                            <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/app-ios-1.png" /></a>
                        </div>
                    </div>
                    <!--<div class="d-flex">
                        <div class="social-icon mr-3">
                            <a href="https://www.facebook.com/profile.php?id=61558079368197&sk=about" target="_blank"><img src="../../../assets/icons/football/ic_facebook.png" /></a>
                        </div>
                        <div class="social-icon">
                            <a href="https://www.instagram.com/kickdun" target="_blank"><img src="../../../assets/icons/football/ic_instagram.png" /></a>
                        </div>
                    </div>-->
                </div>

                <div class="col-12 px-0">
                    <div class="copy-right">
                        Copyright by KICKDUN @2024
                    </div>
                </div>
            </div>
        </div>
        <div class="menu-shadow" v-if="showMenuListItems" @click="showMenuListItems = false"></div>
    </div>
</template>

<script>
import jq from "jquery";
import { slider, slideritem } from 'vue-concise-slider'
export default {
    name: "home",
    components: {
        slider,
        slideritem
    },
    data() {
        return {
            options: {
                currentPage: 0,
                speed: 300,
                autoplay: 2000,
                itemAnimation: true,
                centeredSlides: true,
                thresholdDistance: 100,
                thresholdTime: 300,
                loopedSlides: 2,
                slidesToScroll: 1,
                loop: true
            },
            imgArr: [
                {
                    //img: require("@/assets/icons/football/img_appscreen.png"),
                    img: require("@/assets/icons/football/img_community.png"),
                },
                {
                    //img: require("@/assets/icons/football/img_clubdetailscreen.png")
                    img: require("@/assets/icons/football/img_match.png")
                },
                {
                    //img: require("@/assets/icons/football/banner_image.png")
                    img: require("@/assets/icons/football/img_ranking.png")
                },
                {
                    img: require("@/assets/icons/football/img_review.png")
                },
            ],
            lang: {
                label: 'KR',
                value: 'kr',
                flag: require("@/assets/icons/football/flag_KR.png")
            },
            showMenuListItems: false,
            langArr: [
                {
                    label: 'ENG',
                    value: 'en',
                    flag: require("@/assets/icons/football/flag_UK.png")
                },
                {
                    label: 'VI',
                    value: 'vi',
                    flag: require("@/assets/icons/football/flag_VN.png")
                },
                {
                    label: 'KR',
                    value: 'kr',
                    flag: require("@/assets/icons/football/flag_KR.png")
                }
            ],
            menuItems: [
                {
                    link: "#app-featureds",
                    name: "앱 기능"
                },
                {
                    link: "#work",
                    name: "이 앱의 작동 방식"
                },
                // {
                //     link: "#work",
                //     name: "Đối tác"
                // }
            ],
            partners: [
                {   
                    title: "축구 클럽 만들기",
                    logo: require("@/assets/icons/football/football-badge-svgrepo-com.png"),
                    content: "몇 번의 탭만으로 나만의 축구 클럽을 시작하세요. 팀 이름, 로고, 색상을 포함하여 클럽 프로필을 사용자 지정하세요. 친구나 다른 사용자를 초대하여 클럽에 가입시키고 팀원을 손쉽게 관리하세요."
                },
                {
                    title: "팀원 찾기",
                    logo: require("@/assets/icons/football/groups_black_24dp_outline.png"),
                    content: "다음 경기에 선수가 부족하신가요? 저희 앱을 사용하여 잠재적인 팀원을 검색하고 연결하세요. 위치, 기술 수준, 가용성에 따라 검색을 필터링하여 팀에 완벽한 매치를 찾으세요."
                },
                {
                    title: "도전 상대",
                    logo: require("@/assets/icons/football/soccer-ball-svgrepo-com (2).png"),
                    content: "실력을 시험할 준비가 되셨나요? 지역의 다른 팀을 찾아 도전하세요. Kickdun을 사용하면 경기 일정을 잡고, 장소를 설정하고, 상대 팀과 소통하여 경기에 가장 적합한 시간을 정할 수 있습니다."
                },
                {
                    title: "랭킹 및 리더보드",
                    logo: require("@/assets/icons/football/ranking-svgrepo-com.png"),
                    content: "팀의 성과를 추적하고 다른 팀과 비교해 보세요. Kickdund의 랭킹 시스템은 경기 결과에 기반하여 리더보드를 오르고 축구 커뮤니티에서 인정을 받을 수 있도록 합니다."
                }
            ],
            packages: [],
            typeArr: {
                "DAY": "ngày",
                "MONTH": "tháng",
                "YEAR": "năm",
                "WEEK": "tuần"
            },
            functions: [
                {
                    icon: require("@/assets/icons/football/ic_findingoppoent.png"),
                    title: "상대 및 용병 찾기",
                    content: "원하는 지역, 기술 수준, 성별, 연령을 선택하여 상대팀을 찾을 수 있습니다."
                },
                {
                    icon: require("@/assets/icons/football/ic_clubmanagement.png"),
                    title: "클럽 관리",
                    content: "팀원들과 소통하고 클럽을 관리할 수 있는 클럽 페이지를 제공합니다."
                },
                {
                    icon: require("@/assets/icons/football/ic_community.png"),
                    title: "커뮤니티",
                    content: "축구와 풋살에 관한 소식과 흥미로운 이야기를 공유하세요."
                },
                {
                    icon: require("@/assets/icons/football/ic_clubranking.png"),
                    title: "클럽 랭킹 & 리뷰",
                    content: "경기 결과를 통해 클럽의 랭킹을 확인하세요. 클럽의 성과는 계속 향상될 것입니다."
                }
            ]
        }
    },
    watch: {
        "lang"() {
            this.$router.push(`/${this.lang.value}`);
        },
        "$route"() {
            setTimeout(() => {
                const hash = Object.assign({}, this.$route.hash);
                if (hash) {
                    history.pushState("", document.title, window.location.pathname + window.location.search);
                }
            }, 500);
        }
    },
    async created() {
        setTimeout(() => {
            const hash = Object.assign({}, this.$route.hash);
            if (hash) {
                history.pushState("", document.title, window.location.pathname + window.location.search);
            }
        }, 500);
        jq("#planding .function").on('scroll',function(){
            var WindowLeft = jq("#planding .function").scrollLeft();
            var stop = false;
            jq('.planding-box').each(function(i){
                var left = jq('.planding-box').eq(i).offset().left;
                var eleWidth = jq('.planding-box').eq(i).width();
                var scrollLeft = (left + (eleWidth + (10*i)))*i;
                if (scrollLeft < 0) {
                    scrollLeft = 0;
                }
                if(WindowLeft <= scrollLeft && !stop
                ){
                    jq('.planding-box').removeClass('active');
                    jq('.planding-page-item').removeClass('active');
                    jq('.planding-nav .planding-page-item').eq(i).addClass('active');
                    jq('.planding-box').eq(i).addClass('active');
                    stop = true;
                }
            });
        });
    },
    methods: {
        goPolicy() {
            document.title = "Kickdun Policy";
            this.$router.push('/policy');
        },
        // changeLanguage () {
        //     if (this.lang_btn == 'en') {
        //         this.lang_btn = 'vi';
        //     } else {
        //         this.lang_btn = 'en';
        //     }
        // },
        async clickPlandingNav(e, id) {
            jq("#planding .function").animate({
                scrollLeft: '+='+jq(`#planding .function #page-${id}`).offset().left
            }, 0);
            e.stopPropagation();
        },
        async scrollMenuItems(e, link) {
            history.pushState("", document.title, window.location.pathname + window.location.search);
            this.showMenuListItems = false;
            jq("html").animate({
                scrollTop: (jq(link).offset().top-90)
            }, 300);
            e.preventDefault();
        },
    }
};
</script>